import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Button } from "react-bootstrap";
import Navigation from "../../Components/Navigation";
import Footer from "../../Components/Footer";
import involvedgraphic from "../../Images/getinvolved-graphic.png";
import globelg from "../../Images/globe-graphicSM.png";
import smGlobe from "../../Images/globesm.jpg";
export default function GetInvolved() {
  useEffect(() => {
    window.scrollTo(0, 0);
      document.title="Get Involved"
  }, []);
  return (
    <main>
      <Navigation />

      {/* CODE FOR THE MOVEMENT SECTION*/}
      <section>
        <Grid container className="movement-container">
          <Grid
            container
            size={12}
            justifyContent="center"
            className="movement-title"
          >
            <Grid
              size={{ xs: 12, md: 10 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <h1>Join the Movement</h1>
            </Grid>
          </Grid>
          <Grid container size={12}>
            <Grid
              size={{ xs: 12, lg: 5 }}
              padding="1%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems='center'
            >
              <img
              className='involved-img'
                style={{ width: "100%" }}
                src={involvedgraphic}
                alt="Volunteers giving out shirts and goods"
              />

             {/*  <Grid
                container
                justifyContent="center"
                className="button-mobile-spacing"
              >
                <Button className="story-button">JOIN NOW</Button>
              </Grid> */}
            </Grid>
            <Grid container flexDirection='column' justifyContent='center' alignContent='center' size={{ xs: 12, lg:  7}}>
              <h4>NAACP Environmental and Climate Justice Committee</h4>
              <ul className="movement-list">
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Community Outreach:{" "}
                  </span>
                  Engage with community members to raise awareness about
                  environmental and climate justice issues.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Event Planning and Coordination:{" "}
                  </span>
                  Assist in organizing community events, workshops, and
                  meetings.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Policy Advocacy: </span>
                  Advocate for policies that promote environmental justice and
                  sustainable energy.
                </li>
              </ul>

              <p className="movement-list" style={{ fontWeight: "bold" }}>
                Contact
              </p>
              <ul className="movement-list">
                <li>
                  <span style={{ fontWeight: "bold" }}>Email: </span>
                  <a href="mailto:acnaacp@gmail.com">acnaacp@gmail.com</a>
                </li>
                {/* <li>
                  Phone: [Phone number of NAACP Environmental and Climate
                  Justice Committee]
                </li> */}
              </ul>

              <h4>Community Weatherization Coalition (CWC)</h4>
              <ul className="movement-list">
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Home Energy Tune-Ups:{" "}
                  </span>
                  Assist with conducting home energy Tune-Ups to identify areas
                  for improvement.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Weatherization Projects:{" "}
                  </span>
                  Volunteer to help with weatherization projects, such as
                  installing insulation or sealing air leaks.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Community Outreach:{" "}
                  </span>
                  Engage with residents to promote energy efficiency and
                  encourage participation in weatherization programs.
                </li>
              </ul>

              <p className="movement-list" style={{ fontWeight: "bold" }}>
                Contact
              </p>
              <ul className="movement-list">
                <li>
                  <span style={{ fontWeight: "bold" }}>Email: </span>
                  <a href="mailto:alane@communityweatherization.org">
                    alane@communityweatherization.org
                    
                  </a>
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Phone: </span>
                  (352) 450-4965
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </section>

      {/* CODE FOR THE DONATION SECTION*/}
      <section>
        <Grid container size={12} className="donate-container">
          {/* Content on the left */}
          <Grid
            container
            size={{ xs: 12, sm: 12, md: 12, lg: 8 }}
            direction="column"
          >
            <h4>Donations</h4>
            <h5>Coming Soon! </h5>
            {/* <p>
              Your generous donations to the Solar for All Community Fund
              directly support Project EMPOWER’s mission to address energy
              inequity and promote clean energy in Alachua County. Contributions
              will be used to:
            </p>
            <ul className="movement-list">
              <li>
                Fund Weatherization Programs: Help low-income families improve
                the energy efficiency of their homes.
              </li>
              <li>
                Support Green Job Training: Provide training and job placement
                opportunities for residents seeking careers in the clean energy
                sector.
              </li>
              <li>
                Establish Resilience Hubs: Create community centers that can
                provide support during emergencies and promote sustainable
                living practices.
              </li>
            </ul>

            <h4>How to Donate:</h4>
            <ul className="movement-list">
              <li>
                Online: Visit our website and make a secure online donation.
              </li>
              <li>
                By Mail: Send a check made payable to “EMPOWER Coalition” to:
                [Mailing address of EMPOWER Coalition]
              </li>
              <li>In Person: Visit our office to make a donation in person.</li>
            </ul> */}

            <hr />
            <Grid container size={12}>
              <Grid size={{ xs: 12, md: 6 }}>
                <ul className="movement-list">
                  <h5 className='contact-title'>Alachua County</h5>
                  <li
                    style={{
                      overflowWrap: "break-word",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Website: </span>
                    <a
                      href="https://alachuacounty.us/Pages/AlachuaCounty.aspx"
                      style={{ color: "white" }}
                      target='_blank'
                    >
                      https://alachuacounty.us/Pages/AlachuaCounty.aspx
                    </a>
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Phone: </span>
                    (352) 264-6908
                  </li>
                </ul>

                <ul className="movement-list">
                  <h5  className='contact-title'>City of Gainesville</h5>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Website: </span>
                    <a
                      href="https://gainesvillecra.com/"
                      style={{ color: "white" }}
                      target='_blank'
                    >
                      https://gainesvillecra.com/
                    </a>
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Phone: </span>
                    (352) 393-8200
                  </li>
                </ul>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <ul className="movement-list">
                  <h5  className='contact-title'>NAACP Alachua County Branch</h5>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Website: </span>
                    <a href="https://naacpacb.org/" style={{ color: "white" }}  target='_blank'>
                      https://naacpacb.org/
                    </a>
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Phone: </span>
                    (352) 363-4483
                  </li>
                </ul>

                <ul className="movement-list">
                  <h5  className='contact-title'>Community Weatherization Coalition</h5>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Website: </span>
                    <a
                      href="https://communityweatherization.org/"
                      style={{ color: "white" }}
                      target='_blank'
                    >
                      https://communityweatherization.org/{" "}
                    </a>
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Phone: </span>
                    (352) 450-4965
                  </li>
                </ul>
              </Grid>
            </Grid>
            <p>
              By getting involved, you can help to create a more sustainable and
              equitable future for Alachua County.
            </p>
          </Grid>

          {/* Image on the right */}
          <Grid
            size={{ xs: 12, sm: 12, md: 12, lg: 4 }}
            className="donation-image-grid"
            container
            justifyContent="center"
            alignItems="center"
          >
            <picture>
              <source srcSet={globelg} media="(min-width: 1200px)" />
              <source srcSet={smGlobe} media="(max-width: 1200px)" />
              <img
                // style={{
                //   width:
                //     // "100%"
                //     "70%",
                // }}
                src={smGlobe}
                alt="Hands holding a globe with a plant growing up from the top"
              />
            </picture>
          </Grid>
        </Grid>
      </section>

      <Footer />
    </main>
  );
}
