import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import Navigation from "../../Components/Navigation";
import Footer from "../../Components/Footer";

import volunteers from '../../Images/volunteers.jpg'
import volunteersSM from '../../Images/volunteersSM.jpg'
import partnership from '../../Images/partnership.jpg'
export default function CommunityEngagement() {
  useEffect(() => {
    window.scrollTo(0, 0);
       document.title="Community Engagement"
  }, []);
  return (
    <main>
      <Navigation />

      <section >
        <Grid container size={12} justifyContent="center" className="engagement-container">
          <Grid container size={12} justifyContent='center' paddingBottom='4%'>
            <h1>Empowering Communities Together</h1>
          </Grid>
     
          <Grid container size={{xs:12,md:12,lg:6}} justifyContent='center' padding='0 2%' >
              <img src={volunteers} className='volunteer-img' alt="From the CWC website highlighting their volunteers." />
              
          </Grid>
          <Grid container size={{xs:12,md:12,lg:6}} flexDirection='column' padding='0% 2%'className='involvement-wrapper'>
    <h4>Community Involvement</h4>
    <p>Project EMPOWER is committed to fostering a collaborative and inclusive environment where community members can actively participate in shaping the clean energy transition. We believe that by involving residents in the decision-making process, we can ensure that our initiatives are responsive to local needs and priorities.</p>
    <ul className="pipeline-list">
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Community Meetings:  </span>
           EMPOWER regularly organizes community meetings to discuss project updates, gather feedback, and address concerns. These meetings provide a platform for residents to share their ideas and perspectives.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Volunteer Opportunities:  </span>
                EMPOWER offers a variety of volunteer opportunities, including assisting with outreach efforts, participating in community events, and supporting project implementation.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                	Feedback and Input: {" "}
                </span>
                EMPOWER encourages community members to provide feedback and input on our initiatives. This feedback is invaluable in helping us refine our approach and ensure that our programs are meeting the needs of the community.
              </li>
            </ul>
          </Grid>
    
        </Grid>
      </section>




      <section className="partnership-container" >
        <Grid container size={12} justifyContent="center" className="partnership-container">
          <Grid container size={12} justifyContent='center' paddingBottom='4%'>
            <h1>Partnerships</h1>
          </Grid>

          <Grid container size={{xs:12,md:12,lg:7}} flexDirection='column' padding='0% 2%' >
<p>EMPOWER recognizes the importance of partnerships in achieving its goals. By collaborating with local organizations, government agencies, and other stakeholders, we can leverage diverse expertise, resources, and networks to advance our mission.</p>
    <ul className="pipeline-list">
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Local Organizations: </span>
                EMPOWER works closely with community-based organizations to identify and address local needs. These partnerships help to ensure that our initiatives are responsive to the specific challenges and opportunities facing different neighborhoods.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Government Agencies: </span>
                EMPOWER collaborates with government agencies at the local, state, and federal levels to secure funding, obtain permits, and leverage policy support.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                Private Sector: </span>
                EMPOWER partners with businesses and entrepreneurs in the clean energy sector to create job opportunities and promote sustainable energy solutions.
              </li>
            </ul>
            <p>By building strong partnerships, EMPOWER is able to leverage a wide range of resources and expertise to achieve its objectives and create a more sustainable and equitable future for Alachua County. </p>
          </Grid>
     
          <Grid container size={{xs:12,md:12,lg:5}} justifyContent='center' padding='0 2%'>
              <img src={partnership} width='100%' className='committee-img' alt="Courtesy NAACP Climate Justice Committee.  Nkwanda Jah and Lauren Poe at a September 2022 NAACP holding an energy efficiency illustration used in community outreach efforts."  />
             
          </Grid>
         
    
        </Grid>
      </section>
      <Footer />
    </main>
  );
}
